function getDataLocation() {
  return new Promise(function (resolve, reject) {
    // setTimeout(() => reject(new Error("Whoops!")), 1000);

    // Get User's Coordinate from their Browser
    // window.onload = function() {
    // HTML5/W3C Geolocation
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(UserLocation, CantGetLocaton);
    } else {
      // NearestCity(-6.218487, 106.802525);
      var res = createDistance(-6.218487, 106.802525);
      resolve(res);
    }

    // Callback function for asynchronous call to HTML5 geolocation
    function UserLocation(position) {
      NearestCity(position.coords.latitude, position.coords.longitude);
      var res = createDistance(
        position.coords.latitude,
        position.coords.longitude
      );
      resolve(res);
    }

    function CantGetLocaton(err) {
      var res = createDistance(-6.218487, 106.802525);
      resolve(res);
    }

    // Convert Degress to Radians
    function Deg2Rad(deg) {
      return (deg * Math.PI) / 180;
    }

    function PythagorasEquirectangular(lat1, lon1, lat2, lon2) {
      lat1 = Deg2Rad(lat1);
      lat2 = Deg2Rad(lat2);
      lon1 = Deg2Rad(lon1);
      lon2 = Deg2Rad(lon2);
      var R = 6371; // km
      var x = (lon2 - lon1) * Math.cos((lat1 + lat2) / 2);
      var y = lat2 - lat1;
      var d = Math.sqrt(x * x + y * y) * R;
      return d;
    }

    var lat = 20; // user's latitude
    var lon = 40; // user's longitude

    var cities = [
      ['lexus menteng', -6.201661, 106.846015, 'jl durian'],
      ['lexus mampang', -6.244619, 106.826067, 'asd'],
      ['lexus pluit', -6.125562, 106.797826, 'sddd'],
    ];

    function createDistance(latitude, longitude) {
      for (var i = 0; i < stores.length; i++) {
        var el = stores[i];
        el['distance'] = PythagorasEquirectangular(
          latitude,
          longitude,
          el['lat'],
          el['lon']
        );
      }

      stores.sort(function (a, b) {
        return parseFloat(a.distance) - parseFloat(b.distance);
      });

      return stores;
      // ct.sort(, compare)
    }

    function NearestCity(latitude, longitude) {
      var minDif = 99999;
      var closest;

      for (var index = 0; index < cities.length; ++index) {
        var dif = PythagorasEquirectangular(
          latitude,
          longitude,
          cities[index][1],
          cities[index][2]
        );
        if (dif < minDif) {
          closest = index;
          minDif = dif;
        }
      }

      return cities[closest];

      // echo the nearest city
      // alert(cities[closest]);
    }
  });
}

function video() {
  /*
  var stepsActive = $('.steps').find('.step-line');
  const player = new Plyr('#player', {
    controls: ['play-large', 'play'],
    autoplay: true,
    seekTime: 1,
  });

  player.on('ready', (event) => {
    console.log('VIDEO READY');
    stepsActive.addClass('.step-line');
    var buttonPlay = $('.plyr__control');
    buttonPlay.click();
    buttonPlay.addClass('video-isplay');
    autoRedirect(true);
  });
  */

  $('.js-background-video').bgVideo({
    fullScreen: false, // Sets the video to be fixed to the full window - your <video> and it's container should be direct descendents of the <body> tag
    fadeIn: 0, // Milliseconds to fade video in/out (0 for no fade)
    pauseAfter: 120, // Seconds to play before pausing (0 for forever)
    fadeOnPause: false, // For all (including manual) pauses
    fadeOnEnd: true, // When we've reached the pauseAfter time
    showPausePlay: true, // Show pause/play button
    pausePlayXPos: 'right', // left|right|center
    pausePlayYPos: 'top', // top|bottom|center
    looping: false,
    pausePlayXOffset: '15px', // pixels or percent from side - ignored if positioned center
    pausePlayYOffset: '15px', // pixels or percent from top/bottom - ignored if positioned center
  });
}

function popup() {
  var popup = $('.js-popup');
  if (popup.hasClass('show')) {
    popup.fadeIn(500, function () {});
  }
  popup.on('click', '.js-popup-close', function (event) {
    event.preventDefault();
    popup.fadeOut(500, function () {});
  });

  function triggerPopup(popup) {
    $('body').on('click', '.js-show-popup', function (event) {
      event.preventDefault();
      popup.fadeIn(500, function () {});
    });
  }

  triggerPopup(popup);
}

function renderStore(data) {
  var contentWrap = $('.js-content-area');
  var temp = [];
  var storeType = contentWrap.data('type');

  $.each(data, function (index, val) {
    /* iterate through array or object */
    var data = `
	        <div data-json='${JSON.stringify(
            val
          )}' class="card clearfix anim del-${index} ${
      storeType === 'book' ? 'js-book' : 'js-talk'
    } " data-type="${storeType}">
	          <div class="card__img">
	            <img src="${val.img}">
	          </div>
	          <div class="card__content">
	            <div class="card__content-in">
	              <h2 class="fw-400 fz-16 gutter-bottom-mini text-capilalize">${
                  val.name
                }</h2>
	              <p class="text">${val.address}</p>
	              <footer class="card__footer clearfix">
	                <div class="f-left">
	                  <span class="d-ib text tel">Telephone</span>
	                  <span class="d-ib fw-400 gutter-left-mini">
	                    <a href="tel:${val.phone}">${val.phone}</a>
	                  </span>
	                </div>
	                <div class="f-right only-desktop">
	                  <span class="">SELECT</span>
	                </div>
	              </footer>
	            </div>
	          </div>
	        </div>
		`;

    temp.push(data);
  });
  contentWrap.html(temp.join(''));
  contentWrap.children().each(function (index, el) {
    setTimeout(function () {
      $(el).addClass('anim-ready');
    }, 300 * index);
  });
}

function lattering() {
  $('.js-lattering').lettering('words');
}

function autoRedirect(withCheckVideo) {
  var videoPlayer = $('#player');

  var autoRedirect = $('.js-auto-redirect');
  var redirectDelay = Number(autoRedirect.data('delay'));
  var redirectTo = autoRedirect.data('to');

  if (withCheckVideo && autoRedirect.length) {
    setTimeout(function () {
      window.location.href = redirectTo;
    }, redirectDelay);
  } else {
    if (!videoPlayer.length && autoRedirect.length) {
      setTimeout(function () {
        window.location.href = redirectTo;
      }, redirectDelay);
    }
  }
}

function initCalender() {
  var targetValue = $('.js-calender-value');
  var calenderWrapper = $('.js-calender');
  if (calenderWrapper.length) {
    calenderWrapper.pignoseCalendar({
      week: 1,
      // toggle: true,
      modal: true,
      modals: true,
      buttons: true,
      init: function (context) {
        /**
         * @params context PignoseCalendarContext
         * @returns void
         */

        // This is chaining Element, It is exactly same as the each elements of $('.calendar').
        var $this = $(this);

        // You can get target element in `context` variable.
        var $element = context.element;

        // You can also get calendar element, It is calendar view DOM.
        var $calendar = context.calendar;
        console.log('HERE');

        // console.log($this);
      },
      click: function (event, context) {
        /**
         * @params this Element
         * @params event MouseEvent
         * @params context PignoseCalendarContext
         * @returns void
         */

        // console.log("CLIC");

        // This is clicked button Element.
        var $this = $(this);

        // You can access event parameter.
        event.preventDefault();

        // You can get target element in `context` variable, This element is same `$(this)`.
        var $element = context.element;

        // You can also get calendar element, It is calendar view DOM.
        var $calendar = context.calendar;
      },
      apply: function apply(date, context) {
        /**
         * @params this Element
         * @params date moment[]
         * @params context PignoseCalendarContext
         * @returns void
         */
        // console.log("apply", date);
        // this is calendar element, It is exactly the same as `context.calendar`.
        var $this = $(this); // You can get target element in `context` variable.

        var $element = context.element; // You can also get calendar element, It is calendar view DOM.

        var $calendar = context.calendar;

        //$this.parents('.js-popup').addClass('popup-is-close').fadeOut('500', function () {}); // Selected dates (start date, end date) is passed at first parameter, And this parameters are moment type.
        // If you unselected date, It will be `null`.
        window.location.href =
          window.bookSelected.book_url +
          '?date=' +
          moment(date[0]['_d']).format('MM-DD-YYYY') +
          '&t=' +
          moment(date[0]['_d']).format('DD MM YYYY hh:mm:ss');
      },
    });
  }

  function setDateValue(date) {
    var dateValue = date[0]['_d'];
    var formatValue = moment(dateValue).format('YYYY-MM-DD HH:mm:ss');
    targetValue.val(formatValue);
    targetValue.text(formatValue);

    // close popup calender
    var popup = $('.js-popup');
    popup.fadeIn(500, function () {});
  }

  // Remove default  button
  var calendeerButtonWrap = calenderWrapper.find(
    '.pignose-calendar-button-group'
  );
  // calendeerButtonWrap.find('.pignose-calendar-button-cancel').remove();

  // Add cancel button
  calendeerButtonWrap
    .addClass('test')
    .append(
      '<a href="#" class="pignose-calendar-button pignose-calendar-button-cancel">Cancel</a>'
    );

  // Trigger Cancel; Hide popup
  // $('body').on('click', '.pignose-calendar-button', function(event) {
  // 	event.preventDefault();
  // 	calendeerButtonWrap.parents('.js-popup').fadeOut('500', function() {
  // 	});
  // });
}

function storeEvent() {
  $('body').on('click', '.js-book', function (event) {
    event.preventDefault();
    var data = $(this).data('json');
    window.bookSelected = data;
    $('body').find('.js-calender').click();
  });

  $('body').on('click', '.js-talk', function (event) {
    event.preventDefault();
    var data = $(this).data('json');
    if (data && data.url) {
      window.location.href = data.url;
    } else {
      console.log('NO DATA URL');
    }
  });
}

function seledtedSlider(landingSwiperWrapper) {
  landingSwiperWrapper.on('click', '.js-select-slider', function (event) {
    event.preventDefault();
    var target = $(this).parents('.swiper-slide');
    target.addClass('selected');
    $('.js-slider-val').val(target.data('value'));
  });
}

function scrollTo(y) {
  $('html, body').animate({ scrollTop: y }, 600, function () {
    console.log('END');
  });
  return false;
}

function landingSlider() {
  var landingSwiperWrapper = $('.js-swiper-landing');
  var sliderSelector = '.js-swiper-landing';
  var options = {
    init: false,
    loop: true,
    speed: 800,
    slidesPerView: detectMob() ? 'auto' : 2,
    centeredSlides: true,
    effect: 'coverflow', // 'cube', 'fade', 'coverflow',
    coverflowEffect: {
      rotate: 2, // Slide rotate in degrees
      stretch: 40, // Stretch space between slides (in px)
      depth: 160, // Depth offset in px (slides translate in Z axis)
      modifier: 1, // Effect multipler
      // slideShadows: true, // Enables slides shadows
    },
    parallax: true,
    navigation: {
      nextEl: '.js-landing-next',
      prevEl: '.js-landing-prev',
    },
    on: {
      imagesReady: function () {
        this.el.classList.remove('loading');
      },
    },
  };

  if (landingSwiperWrapper.length) {
    var landingSwiper = new Swiper(sliderSelector, options);

    // Initialize slider
    landingSwiper.init();

    landingSwiper.on('slideChange', function () {
      landingSwiperWrapper.find('.swiper-slide').removeClass('selected');
    });

    miniSlider(landingSwiper);
    seledtedSlider(landingSwiperWrapper);
  }
}

function bannerSlider() {
  var bannerSwiperWrapper = $('.js-swiper-banner');

  if (bannerSwiperWrapper.length) {
    var bannerSwiper = new Swiper('.js-swiper-banner', {
      effect: 'fade',
      grabCursor: true,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      speed: 1200,
      centeredSlides: true,
      slidesPerView: 1,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    });
  }
}

function miniSlider(landingSwiper) {
  var swipperMiniWrapper = $('.js-swiper-mini');

  if (swipperMiniWrapper.length) {
    var swipperMiniWrapperY = swipperMiniWrapper.offset().top;

    var miniSwiper = new Swiper('.js-swiper-mini', {
      slidesPerView: 4,
      effect: 'slide',
      spaceBetween: 0,
      // initialSlide: 1,
      pagination: {
        el: '.js-mini-swiper-pagination',
        // clickable: false,
      },
      navigation: {
        nextEl: '.js-swiper-mini-next',
        prevEl: '.js-swiper-mini-prev',
      },
      breakpoints: {
        768: {
          slidesPerView: 4,
        },
        1024: {
          slidesPerView: 5,
        },
      },
    });

    swipperMiniWrapper.on('click', '.swiper-slide', function (event) {
      event.preventDefault();
      var _this = $(this);
      var _index = _this.data('index');

      var _parentWrapper = _this.parents('.swiper-mini-wrapper');

      // RUN SCROOL TO WHEN SLIDER TOP;
      if (_parentWrapper.hasClass('swiper-top-wrapper')) {
        scrollTo(swipperMiniWrapperY - 40);
      }
      landingSwiper.slideTo(Number(_index), 500, function () {
        console.log('SLIDE MOVE');
      });
    });
  }
}

function detailSlider() {
  var swipperDetailWrapper = $('.js-detail-swipper');
  if (swipperDetailWrapper.length) {
    var swiper = new Swiper('.js-detail-swipper', {
      slidesPerView: 'auto',
      centeredSlides: true,
      spaceBetween: 10,
      initialSlide: 1,
      preloadImages: false,
      navigation: {
        nextEl: '.js-swiper-detail-next',
        prevEl: '.js-swiper-detail-prev',
      },
      lazy: {
        loadPrevNext: true,
      },
    });
  }
}

function inputLabel() {
  function hanldeInputChange(el) {
    $(el).on('change keyup paste input', function (e) {
      var _this = $(this);
      if (_this.val()) {
        _this.addClass('has-value');
      } else {
        _this.removeClass('has-value');
      }
    });
  }
  $('.form-control').each(function (index, el) {
    var _el = $(el);
    if (_el.val()) {
      _el.addClass('has-value');
    }
    hanldeInputChange(el);
  });
}

function initReady(_body) {
  var _preloader = $('#preloader');
  _body.addClass('doc-ready loaded');
  setTimeout(function () {
    _preloader.hide();
  }, 300);
  setTimeout(function () {
    _body.addClass('finish-animation');
  }, 2000);
  $(window).on('load', function () {
    _body.addClass('doc-load');
  });
}

function wrapColorsLists() {
  var childWrap = 5;
  $('.js-car-colors').each(function () {
    var divs = $('li', this);
    for (var i = 0; i < divs.length; i += childWrap) {
      console.log(i);
      divs.slice(i, i + childWrap).wrapAll('<div class="wrapper"></div>');
    }
  });
}

function customSelect() {
  var x, i, j, l, ll, selElmnt, a, b, c;
  /*look for any elements with the class "custom-select":*/
  x = document.getElementsByClassName('custom-select');
  l = x.length;
  for (i = 0; i < l; i++) {
    selElmnt = x[i].getElementsByTagName('select')[0];
    ll = selElmnt.length;
    /*for each element, create a new DIV that will act as the selected item:*/
    a = document.createElement('DIV');
    a.setAttribute('class', 'select-selected');
    a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
    x[i].appendChild(a);
    /*for each element, create a new DIV that will contain the option list:*/
    b = document.createElement('DIV');
    b.setAttribute('class', 'select-items select-hide');
    for (j = 1; j < ll; j++) {
      /*for each option in the original select element,
		create a new DIV that will act as an option item:*/
      c = document.createElement('DIV');
      c.innerHTML = selElmnt.options[j].innerHTML;
      c.addEventListener('click', function (e) {
        /*when an item is clicked, update the original select box,
			and the selected item:*/
        var y, i, k, s, h, sl, yl;
        s = this.parentNode.parentNode.getElementsByTagName('select')[0];
        sl = s.length;
        h = this.parentNode.previousSibling;
        for (i = 0; i < sl; i++) {
          if (s.options[i].innerHTML == this.innerHTML) {
            s.selectedIndex = i;
            h.innerHTML = this.innerHTML;
            y = this.parentNode.getElementsByClassName('same-as-selected');
            yl = y.length;
            for (k = 0; k < yl; k++) {
              y[k].removeAttribute('class');
            }
            this.setAttribute('class', 'same-as-selected');
            break;
          }
        }
        h.click();
      });
      b.appendChild(c);
    }
    x[i].appendChild(b);
    a.addEventListener('click', function (e) {
      /*when the select box is clicked, close any other select boxes,
		and open/close the current select box:*/
      e.stopPropagation();
      closeAllSelect(this);
      this.nextSibling.classList.toggle('select-hide');
      this.classList.toggle('select-arrow-active');
    });
  }
  function closeAllSelect(elmnt) {
    /*a function that will close all select boxes in the document,
	except the current select box:*/
    var x,
      y,
      i,
      xl,
      yl,
      arrNo = [];
    x = document.getElementsByClassName('select-items');
    y = document.getElementsByClassName('select-selected');
    xl = x.length;
    yl = y.length;
    for (i = 0; i < yl; i++) {
      if (elmnt == y[i]) {
        arrNo.push(i);
      } else {
        y[i].classList.remove('select-arrow-active');
      }
    }
    for (i = 0; i < xl; i++) {
      if (arrNo.indexOf(i)) {
        x[i].classList.add('select-hide');
      }
    }
  }
  /*if the user clicks anywhere outside the select box,
	then close all select boxes:*/
  document.addEventListener('click', closeAllSelect);
}

var navbars = (_body) => {
  var _navbarContainer = $('.navbar.navbar-space');

  if (_navbarContainer.length) {
    console.log('H', _navbarContainer.height());
    _body.addClass('navbar-space');
  }

  var _navbar = $('.js-navbar');
  window.onscroll = function () {
    windowScroll();
  };

  function windowScroll() {
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      _navbar.removeClass('navbar-transparent');
    } else {
      _navbar.addClass('navbar-transparent');
    }
  }
};

var fadeScroll = (_body) => {
  var _windowHeight = $(window).height();
  var fadeStart = 100, // 100px scroll or less will equiv to 1 opacity
    fadeUntil = _windowHeight, // 200px scroll or more will equiv to 0 opacity
    fadingTarget = $('.swiper-mini-wrapper');
  $(window).on('scroll', function () {
    var offset = $(document).scrollTop(),
      opacity = 0;
    if (offset <= fadeStart) {
      opacity = 1;
    } else if (offset <= fadeUntil) {
      opacity = 1 - offset / fadeUntil;
    }
    console.log(opacity);
    fadingTarget.css('opacity', opacity);
  });
};

$(document).ready(function ($) {
  var storeWrapper = $('.js-content-area');
  var storeType = storeWrapper.data('type');
  var _body = $('body');

  if (storeWrapper.length && window.stores) {
    getDataLocation().then((res) => {
      setTimeout(
        function () {
          renderStore(res);
        },
        storeType === 'book' ? 50 : 20
      );
    });
  }

  initReady(_body);
  storeEvent();

  initCalender();
  lattering();
  autoRedirect(false);
  video();
  popup();
  detailSlider();
  bannerSlider();
  landingSlider();
  inputLabel();
  wrapColorsLists();

  customSelect();
  navbars(_body);
  // fadeScroll(_body);
});
